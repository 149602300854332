import axios from "axios";

// const server = "http://localhost:8083";
const server = "https://services.anteia.co/dummies";
// const server = "https://dummies-back.anteia.co/"

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

export async function getColorsByFlowId(id: string): Promise<BrandConfig> {
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/flow/getColors?flowId=${id}`
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log("error status: " + response.status);
      return {
        backgroundColor1: "#047bd6",
        backgroundColor2: "#000e1a",
        logoUrl: "",
      };
    }
  } catch (err) {
    console.log("error: " + err);
    return {
      backgroundColor1: "#047bd6",
      backgroundColor2: "#000e1a",
      logoUrl: "",
    };
  }
}

export async function getColorsByProcessId(id: string): Promise<BrandConfig> {
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/flow/getColorsProcessId?processId=${id}`
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log("error status: " + response.status);
      return {
        backgroundColor1: "#047bd6",
        backgroundColor2: "#000e1a",
        logoUrl: "",
      };
    }
  } catch (err) {
    console.log("error: " + err);
    return {
      backgroundColor1: "#047bd6",
      backgroundColor2: "#000e1a",
      logoUrl: "",
    };
  }
}

export async function create(
  flowId: string,
  email: string
): Promise<FlowProcess> {
  try {
    let response = await axios.get<FlowProcess>(
      `${server}/flow/create?flowId=${flowId}&email=${email}`
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function getCoreIdLink(
  processId: string
): Promise<GetUrlResponse> {
  try {
    let response = await axios.get<GetUrlResponse>(
      `${server}/flow/getCoreIdLink?processId=${processId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function getElecSigOnlyRedir(
  flowId: string
): Promise<GetUrlResponse> {
  try {
    let response = await axios.get<GetUrlResponse>(
      `${server}/flow/getNewElecSig?flowId=${flowId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function commitCoreId(
  processId: string,
  regId: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/flow/commitCoreId?processId=${processId}&regId=${regId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function addFilledId(
  processId: string,
  filledFormId: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/flow/addFilledId?processId=${processId}&filledFormId=${filledFormId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function getMagicLink(processId: string): Promise<GetUrlResponse> {
  try {
    let response = await axios.get<GetUrlResponse>(
      `${server}/flow/getMagicFormsLink?processId=${processId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function getFlowConfigByProcessId(
  processId: string
): Promise<FlowConfig> {
  try {
    let response = await axios.get<FlowConfig>(
      `${server}/flow/getFlowConfig?processId=${processId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function getElecSigUrl(
  processId: string
): Promise<GetSignatureResponse> {
  try {
    let response = await axios.get<GetSignatureResponse>(
      `${server}/flow/getElecSigUrl?processId=${processId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

export async function sendFinalEmail(processId: string): Promise<void> {
  console.log("Send Email Final....")
  try {
    let response = await axios.get(
      `${server}/flow/sendFinalEmail?processId=${processId}`
    );

    if (response.status == 200) {
      console.log("success");
      console.log(response.data);
      return;
    } else {
      console.log("error status: " + response.status);
      throw Error("Response not 200");
    }
  } catch (err) {
    console.log("error: " + err);
    throw Error("Response not 200");
  }
}

//MODEL

export interface BrandConfig {
  backgroundColor1: string;
  backgroundColor2: string;
  logoUrl: string;
}

export interface FlowProcess {
  id: string;
  userId: string;
  flowId: string;
  email: string;
}

export interface GetUrlResponse {
  url: string;
  expirationDate: string;
}

export interface FlowConfig {
  hasCoreId: boolean;
  coreIdProjectId: string;
  coreIdClientId: string;
  coreIdAPK: string;
  hasMagicForms: boolean;
  formTemplateId: string;
  hasElectronicSignature: boolean;
  electronicSignatureFilename: string;
}

export interface GetSignatureResponse {
  url: string;
  expirationDate: string;
}
