import React, { useEffect, useState } from "react";
import useQuery from "../../../utils/UseQuery";
import { getColorFromCode } from "../../api/magic_forms_2_api";
import { getColorsByExecFlow } from "../../../hyperFlow/hyperFlowApi";

export const RedirectContent: React.FC = () => {
  const [primaryButtonColor, setPrimaryButtonColor] = useState("#ffffff");
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [font, setFont] = useState<string | null>(null);

  const query = useQuery();
  const code = query.get("code") ?? "error";
  const execFlowId = query.get("execFlowId") ?? "error";
  const linkUrl = query.get("linkUrl") ?? "error";

  const fontDictionary: { [key: string]: string } = {
    default: "'Open Sans', sans-serif",
    sourceSans: "'Source Sans 3', sans-serif",
  };

  const getFontFamily = () => {
    if (font) {
      return fontDictionary[font];
    } else {
      return fontDictionary.default;
    }
  };
  const goBack = () => {
    window.history.back();
  };

  async function getColors() {
    let resp = await getColorFromCode(code);
    if (resp.failed) {
      resp = await getColorsByExecFlow(execFlowId);
    }
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setPrimaryButtonColor(
      resp.primaryButtonColor ||
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)"
    );
    setFont(resp.font);
  }

  function urlDecode(encodedUrl: string): string {
    try {
      return decodeURIComponent(encodedUrl);
    } catch (error) {
      console.error("Error decoding URL:", error);
      return encodedUrl; // Return the original string in case of an error
    }
  }

  useEffect(() => {
    getColors();
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        backgroundColor: bgColor[0],
      }}
    >
      <iframe
        title="redirect-content"
        style={{ width: "100%", height: "100%", border: "none" }}
        src={urlDecode(linkUrl)}
      />
      <button
        className="flex justify-center items-center text-[16px] font-semibold min-w-[160px] min-h-[56px] rounded-[30px]"
        style={{
          fontFamily: getFontFamily(),
          position: "absolute",
          bottom: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "10px",
          backgroundColor: primaryButtonColor || "#3498db",
          color: "#fff",
          border: "none",
          cursor: "pointer",
        }}
        onClick={goBack}
      >
        Regresar
      </button>
    </div>
  );
};
