import React from "react";

export interface ButtonComponentProps {
  backgroundColor: string;
  color: string;
  text: string;
  onClick: () => void;
}

export const ButtonComponent = (props: ButtonComponentProps) => {
  return (
    <button
      onClick={props.onClick}
      className="flex justify-center items-center text-[16px] font-semibold min-w-[160px] min-h-[56px] rounded-[30px]"
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
    >
      {props.text}
    </button>
  );
};
