import React, { useState } from "react";
import StyleProperties from "../../dtos/StyleProperties";
import { TextTransform } from "./TextTransform";

interface RadioSelectProps {
  style?: StyleProperties;
  align?: "CENTER" | "LEFT" | "RIGHT";
  onChange?: (selected: string) => void;
  options: string[];
  darkMode?: boolean;
  disabled?: boolean;
  otherDdAct?: boolean;
  onOtherChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  otherValue?: string;
  id: string;
}

export const RadioSelect: React.FC<RadioSelectProps> = ({
  style,
  align,
  onChange,
  options,
  darkMode,
  disabled,
  otherDdAct,
  onOtherChange,
  otherValue,
  id
}) => {
  const [selected, setSelected] = useState<string | null>(null);

  const handleRadioChange = (selectedOption: string) => {
    console.log("selectedOption", selectedOption);
    setSelected(selectedOption);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const getColor = () => {
    if (style && style.color) {
      return style.color;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getAlign = () => {
    if (align === "RIGHT") {
      return "justify-end items-end";
    } else if (align === "LEFT") {
      return "justify-start items-start";
    } else {
      return "justify-center items-center";
    }
  };

  const getDefaultMargin = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
      };
    } else {
      return { margin: "15px 0" };
    }
  };

  const getSelectedColor = (value: string) => {
    if (value === selected) {
      if (style && style.selectedColor) {
        return style.selectedColor;
      } else {
        return darkMode ? "white" : "black";
      }
    } else {
      if (style && style.unselectedColor) {
        return style.unselectedColor;
      } else {
        return darkMode ? "black" : "white";
      }
    }
  };
  const getBorderColor = () => {
    if (style && style.borderColor) {
      return style.borderColor;
    } else {
      return darkMode ? "white" : "black";
    }
  };
  return (
    <div
      className={`relative w-full flex flex-col  ${getAlign()}`}
      style={getDefaultMargin()}
    >
      {options.map((option, i) => {
        return (
          <div key={`${i}:${id}`} className="w-full flex flex-row" style={style}>
            <input
              disabled={disabled}
              type="radio"
              id={`${i}${option}${id}`}
              name={`${i}${option}${id}`}
              value={option}
              onChange={() => {
                handleRadioChange(option);
              }}
              checked={selected === option}
            />
            <label
              className="inline-block relative py-2 px-6 cursor-pointer select-none"
              htmlFor={`${i}${option}${id}`}
            >
              <span
                style={{
                  backgroundColor:
                    selected === option
                      ? getSelectedColor(option)
                      : "transparent",
                  borderColor: getBorderColor(),
                }}
                className={`inline-block absolute left-0 top-1/2 transform -translate-y-1/2 w-[16px] h-[16px] border-2  rounded-full  transition `}
              ></span>
              <span>
                <TextTransform text={option} style={style} />
              </span>
            </label>
          </div>
        );
      })}
      {otherDdAct && (
        <input
          placeholder={"¿Cual?"}
          style={{
            ...style,
            padding: "0",
            color: getColor(),
            borderColor: getBorderColor(),
            margin: "15px 0",
          }}
          className="bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]"
          type="text"
          value={otherValue}
          disabled={disabled}
          onChange={(ev) => {
            if (onOtherChange) {
              onOtherChange(ev);
            }
          }}
        />
      )}
    </div>
  );
};
