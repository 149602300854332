import Swal, { SweetAlertResult } from "sweetalert2";

const vars = {
  background: "#E0E0E0",
  cancelButtonColor: "#5A3278",
  confirmButtonColor: "#5A3278",
  denyButtonColor: "#5A3278",
  iconColor: "#5A3278",
};

export async function hfErrorAlert(message: string): Promise<SweetAlertResult> {
  // Fire error swal
  return Swal.fire({
    ...vars,
    icon: "error",
    title: "Oops...",
    text: message,
    footer: "",
  });
}

export async function hfSuccessAlert(
  message: string
): Promise<SweetAlertResult> {
  // Fire success swal
  return Swal.fire({
    ...vars,
    icon: "success",
    title: "¡Exito!",
    text: message,
    footer: "",
  });
}

export async function hfWarningAlert(
  message: string
): Promise<SweetAlertResult> {
  // Fire warning swal
  return Swal.fire({
    ...vars,
    icon: "warning",
    title: "¡Atención!",
    text: message,
    footer: "",
  });
}

export async function hfYesNoModal(
  title: string,
  message: string
): Promise<boolean> {
  // Fire yes no swal
  let result = await Swal.fire({
    ...vars,
    title: title,
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Si",
    cancelButtonText: "No",
  });
  return result.isConfirmed;
}
