import StyleProperties from "../../dtos/StyleProperties";

export interface TextTransformProps {
  text: string;
  style?: StyleProperties;
}

export const TextTransform = ({ text, style }: TextTransformProps) => {
  const linkPattern = /{{link:\s*(.*?),\s*(.*?)}}/g;

  const parts = text.split(linkPattern);

  // console.log("parts", parts);

  function urlEncode(decodedUrl: string): string {
    try {
      return encodeURIComponent(decodedUrl);
    } catch (error) {
      console.error("Error encoding URL:", error);
      return decodedUrl; // Return the original string in case of an error
    }
  }

  const getRedirectLinkContent = (url: string) => {
    const origin = window.location.origin;
    const search = window.location.search;
    const newPath = "/magicForms/redirectContent";
    const newUrl = `${origin}${newPath}${search}&linkUrl=${urlEncode(url)}`;
    return newUrl;
  };

  const elements = parts.map((part, index) => {
    if (index % 3 === 1) {
      const url = parts[index];

      return (
        <a
          key={index}
          // href={getRedirectLinkContent(url)}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration:
              style && style.linkUnderlined ? "underlined" : "none",
            color: style && style.linkColor,
          }}
        >
          {parts[index + 1]}
        </a>
      );
    } else if (index % 3 === 0) {
      return (
        <span key={index} style={style}>
          {part}
        </span>
      );
    }
  });
  return <>{elements}</>;
};
