import { useEffect, useRef, useState, forwardRef } from "react";
import "./InitialForm.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import { parse } from "date-fns";
import es from "date-fns/locale/es";
import imgFlecha from "../../assets/flechaArriba.png";
import { AutoCompleteInput } from "./components/AutoCompleteInput";
import { SelectInput } from "./components/SelectInput";
import { RadioSelect } from "./components/RadioSelect";
import { CheckBoxSelect } from "./components/CheckBoxSelect";
import { CurrencyInput } from "./components/CurrencyInput";
import { SliderButton } from "./components/SliderButton";
import { DaneInput } from "./components/DaneInput";
import { TextTransform } from "./components/TextTransform";

registerLocale("es", es);

/**
 * types: text, money, multiple
 * @param {*} param0
 * @param {Array} options options
 * @param {FieldValidator} validator
 * @returns
 */
export const GenericField2 = ({
  value,
  onChange,
  title,
  fieldType,
  style,
  align,
  description,
  options,
  supportsOther,
  disabled = false,
  darkMode = true,
  subFields,
  fieldValidator,
  primaryButtonColor,
  stitchTypeId,
  state,
}) => {
  if (options.includes("CEDULA")) {
    console.log(fieldType);
  }
  let [otherVal, setOtherVal] = useState("");
  let [multiVal, setMultiVal] = useState(value);
  const [dateE, setDate] = useState(null);
  //dd special
  const [otherDdAct, setOtherDdAct] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  //file ref
  const fileRef = useRef(null);

  const getAlign = () => {
    if (align === "RIGHT") {
      return "justify-end items-end";
    } else if (align === "LEFT") {
      return "justify-start items-start";
    } else {
      return "justify-center items-center ";
    }
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    const getColor = () => {
      if (style && style.color) {
        return style.color;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getBorderColor = () => {
      if (style && style.borderColor) {
        return style.borderColor;
      } else {
        return darkMode ? "white" : "black";
      }
    };
    return (
      <input
        placeholder={title}
        style={{ ...style, color: getColor(), borderColor: getBorderColor() }}
        onClick={onClick}
        ref={ref}
        className={`bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]`}
        type='text'
        readOnly
        required
        value={value}
      />
    );
  });
  useEffect(() => {
    // make the style of the placeholder globally the style color
    let styleTemp = document.createElement("style");
    if (style && style.color) {
      styleTemp.innerHTML = `input::placeholder { color: ${style.color}; }`;
      document.head.appendChild(styleTemp);
    }

    if (fieldType === "date" && value) {
      try {
        let nDate = parse(value, "dd/MM/yyyy", new Date());
        console.log("pasing date success for value: " + value);
        console.log(nDate);
        if (isNaN(nDate.getTime())) {
          throw Error("Error Date");
        }
        setDate(nDate);
      } catch (e) {
        console.log("error on date parsing for value: " + value);
      }
    }
  }, []);
  console.log(fieldType);
  // Money field type
  if (fieldType === "money") {
    return (
      <CurrencyInput
        currency='COP'
        country='es-CO'
        onChange={(eValue) => {
          onChange(eValue);
        }}
        disabled={disabled}
        value={value}
        darkMode={darkMode}
        title={title}
        style={style}
        key={title}
      />
    );
  }
  // Money field type
  if (fieldType === "moneyCr") {
    return (
      <CurrencyInput
        currency='CRC'
        country='es-CO'
        onChange={(eValue) => {
          onChange(eValue);
        }}
        disabled={disabled}
        value={value}
        darkMode={darkMode}
        title={title}
        style={style}
        key={title}
      />
    );
  }
  if (fieldType === "moneyNi") {
    return (
      <CurrencyInput
        currency='NIO'
        country='es-CO'
        onChange={(eValue) => {
          onChange(eValue);
        }}
        disabled={disabled}
        value={value}
        darkMode={darkMode}
        title={title}
        style={style}
        key={title}
      />
    );
  }
  // Multiple choice field
  else if (fieldType === "multipleChoice") {
    const otherSelected = state[`${stitchTypeId}.-1`] === "true" ? true : false;
    let otherValue = state[`${stitchTypeId}.other`];
    otherValue = otherValue ? otherValue : "";
    const getColor = () => {
      if (style && style.color) {
        return style.color;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getBorderColor = () => {
      if (style && style.borderColor) {
        return style.borderColor;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getAlign = () => {
      if (align === "RIGHT") {
        return "justify-end items-end";
      } else if (align === "LEFT") {
        return "justify-start items-start";
      } else {
        return "justify-center items-center";
      }
    };

    const getDefaultMargin = () => {
      if (style && (style.margin || style.marginTop || style.marginBottom)) {
        return {
          margin: style.margin,
          marginTop: style.marginTop,
          marginBottom: style.marginBottom,
        };
      } else {
        return { margin: "15px 0" };
      }
    };
    let checks = [];
    options.map((val, i) => {
      let c = (
        <CheckBoxSelect
          key={i}
          id={`${val}${i}`}
          label={val}
          selectedValue={
            state[`${stitchTypeId}.${i}`] === "true" ? true : false
          }
          color={getColor()}
          onChange={(checked) => {
            onChange(val, checked, i);
          }}
          disabled={disabled}
          style={style}
        />
      );
      checks.push(c);
    });
    if (supportsOther) {
      checks.push(
        <CheckBoxSelect
          key={"Otro"}
          id={`Otro Checkbox`}
          label={"Otro"}
          color={getColor()}
          onChange={(checked) => {
            onChange("", checked, -1);
          }}
          selectedValue={otherSelected}
          disabled={disabled}
          style={style}
        />
      );
    }
    return (
      <div
        className={`relative w-full flex flex-col  ${getAlign()}`}
        style={getDefaultMargin()}
      >
        {title && <p style={style}>{title}</p>}
        <div className='w-full mt-2 space-y-[14px]' style={style}>
          {checks}
        </div>
        {otherSelected && (
          <input
            placeholder={"¿Cual?"}
            style={{
              color: getColor(),
              borderColor: getBorderColor(),
              margin: "15px 0",
            }}
            className='bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]'
            type='text'
            value={otherValue}
            disabled={disabled}
            onChange={(ev) => {
              onChange(ev.target.value, true, -1);
            }}
          />
        )}
      </div>
    );
  }
  // Single choice field
  else if (fieldType === "singleChoice") {
    let values = [];
    options.map((val, i) => {
      values.push(val);
    });
    if (supportsOther) {
      values.push("Otro");
    }
    return (
      <RadioSelect
        options={values}
        onChange={(selectedValue) => {
          let selected = selectedValue;
          setMultiVal(selected);
          onChange(selected);
          if (supportsOther) {
            if (selected === "Otro") {
              setOtherDdAct(true);
            } else {
              setOtherDdAct(false);
            }
            setOtherVal("");
          }
        }}
        align={align}
        style={style}
        value={multiVal}
        darkMode={darkMode}
        onOtherChange={(ev) => {
          let val = ev.target.value;
          setOtherVal(val);
          setMultiVal("");
          onChange(`:otro:${val}`, ev.target.value !== "");
        }}
        otherValue={otherVal}
        otherDdAct={otherDdAct}
        id={stitchTypeId}
      />
    );
  }
  // Dropdown field
  else if (fieldType === "dropdown") {
    let itemsString = [];
    options.map((val) => {
      itemsString.push({ label: val });
    });
    if (supportsOther) {
      itemsString.push({ label: "Otro" });
    }
    return (
      <SelectInput
        title={title}
        selected={multiVal}
        onChange={(e, value) => {
          setMultiVal(value);
          onChange(value.label);
          // onChange()
          // setMultiVal(e.target.value);
          // onChange(e.target.value);

          if (value.label === "Otro") {
            setOtherDdAct(true);
          } else {
            setOtherDdAct(false);
          }
        }}
        disabled={disabled}
        otherDdAct={otherDdAct}
        otherValue={otherVal}
        onOtherChange={(ev) => {
          setOtherVal(ev.target.value);
          onChange(ev.target.value);
        }}
        style={style}
        align={align}
        options={itemsString}
        darkMode={darkMode}
        id={stitchTypeId}
      />
    );
  }
  //Dropdown Dynamic
  else if (
    fieldType === "dropdown_dynamic" ||
    fieldType === "dropdown_from_state"
  ) {
    let itemsString = [];
    options.map((val) => {
      itemsString.push({ label: val });
    });
    if (supportsOther) {
      itemsString.push({ label: "Otro" });
    }
    return (
      <AutoCompleteInput
        style={style}
        selected={multiVal}
        title={title}
        darkMode={darkMode}
        options={itemsString}
        align={align}
        disabled={disabled}
        onChange={(e, nv) => {
          if (!nv) return;
          setMultiVal(nv);
          onChange(nv.label);
          // onChange()
          // setMultiVal(e.target.value);
          // onChange(e.target.value);
          if (nv.label === "Otro") {
            setOtherDdAct(true);
          } else {
            setOtherDdAct(false);
          }
        }}
        otherDdAct={otherDdAct}
        otherValue={otherVal}
        onOtherChange={(ev) => {
          setOtherVal(ev.target.value);
          onChange(ev.target.value);
        }}
      />
    );
  }
  // File field
  else if (fieldType === "file") {
    // if(fileTypes) {
    const getDefaultMargin = () => {
      if (style && (style.margin || style.marginTop || style.marginBottom)) {
        return {
          margin: style.margin,
          marginTop: style.marginTop,
          marginBottom: style.marginBottom,
        };
      } else {
        return { margin: "15px 0" };
      }
    };
    const getBackgroundColor = () => {
      if (style && style.backgroundColor) {
        return style.backgroundColor;
      } else if (primaryButtonColor) {
        return primaryButtonColor;
      } else {
        return "#2D62ED";
      }
    };
    // }
    return (
      <div
        className={`w-full flex flex-col ${getAlign()}`}
        style={getDefaultMargin()}
      >
        <input
          type='file'
          ref={fileRef}
          style={{ display: "none" }}
          onChange={onChange}
        />
        <button
          className='flex justify-center items-center text-[16px] font-semibold min-w-[180px] min-h-[26px] rounded-[30px]'
          style={{
            ...style,
            backgroundColor: getBackgroundColor(),
            color: darkMode ? "white" : "black",
          }}
          onClick={() => {
            fileRef.current.click();
          }}
        >
          {title}
        </button>
        <p style={style}>{value}</p>
      </div>
    );
  }
  // Number field
  else if (fieldType === "number") {
    const getDefaultMargin = () => {
      if (style && (style.margin || style.marginTop || style.marginBottom)) {
        return {
          margin: style.margin,
          marginTop: style.marginTop,
          marginBottom: style.marginBottom,
        };
      } else {
        return { margin: "15px 0" };
      }
    };
    const getColor = () => {
      if (style && style.color) {
        return style.color;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getBorderColor = () => {
      if (style && style.borderColor) {
        return style.borderColor;
      } else {
        return darkMode ? "white" : "black";
      }
    };
    return (
      <div className={`w-full flex ${getAlign()}`} style={getDefaultMargin()}>
        <input
          placeholder={title}
          style={{ ...style, color: getColor(), borderColor: getBorderColor() }}
          className='bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]'
          type='number'
          value={value}
          disabled={disabled}
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
        />
      </div>
    );
  }
  // Date field
  else if (fieldType === "date") {
    return (
      <div className={`w-full flex ${getAlign()}`}>
        <DatePicker
          wrapperClassName='mt-1 mb-1'
          popperProps={{ strategy: "fixed" }}
          customInput={<DateInput />}
          locale='es'
          selected={dateE}
          onChange={(e) => {
            console.log("changed something!");
            setDate(e);
            if (!e) return;
            console.log(e);
            let date2 = e;
            let month = date2.getMonth() + 1;
            let year = date2.getFullYear();
            let day = date2.getDate();
            let dateString = `${day}/${month}/${year}`;
            onChange(dateString);
          }}
          closeOnScroll={false}
          dateFormat='dd/MM/yyyy'
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          disabled={disabled}
          onFocus={(e) => e.target.blur()}
        />
      </div>
    );
  }
  // Label Field
  else if (fieldType === "label" || fieldType === "labelSummary") {
    return (
      <div className='row' style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <TextTransform
          key={title}
          text={title}
          style={style}
          align={align}
          darkMode={darkMode}
        />
        <p
          className='txt-label'
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "dynamic_label") {
    let line = title.replace("{{}}", value);
    // split line into paragraphs by \n and add them to an array
    let paragraphs = line.split("\n");

    return (
      <div className='row' style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        {/* <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        > */}
        {/* map paragraphs into texts */}
        {paragraphs.map((paragraph, i) => (
          <p
            key={i}
            className='txt-label'
            style={{ marginLeft: "10vw", marginRight: "10vw" }}
          >
            {paragraph}
          </p>
        ))}
        {/* {title.replace("{{}}", value)} */}
        {/* </p> */}
        <p
          className='txt-label'
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "read_more") {
    return (
      <div className='row' style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className='txt-label'
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        {showDesc && (
          <div
            style={{
              height: "30vh",
              overflow: "auto",
            }}
          >
            <p
              className='txt-label'
              style={{
                marginLeft: "2vw",
                marginRight: "2vw",
                fontSize: "16px",
                textAlign: "start",
              }}
            >
              {description}
            </p>
          </div>
        )}
        {!showDesc && (
          <p
            className='txt-label'
            style={{
              marginLeft: "10vw",
              marginRight: "10vw",
              fontSize: "16px",
            }}
          >
            {description.substring(0, 40) + "..."}
          </p>
        )}
        {!showDesc && (
          <p
            className='txt-label'
            onClick={() => {
              setShowDesc(true);
            }}
          >
            {"Leer más    "}{" "}
            <img
              alt='dawdaw'
              src={imgFlecha}
              className='img-flecha-inv'
              style={{
                height: "12px",
                width: "16px",
                transform: [{ rotate: "180deg" }],
              }}
            ></img>
          </p>
        )}
        {showDesc && (
          <p
            className='txt-label'
            style={{ fontSize: "16px" }}
            onClick={() => {
              setShowDesc(false);
            }}
          >
            {"Leer menos   "}
            <img
              alt='dawdaw'
              src={imgFlecha}
              style={{ height: "12px", width: "16px" }}
            ></img>
          </p>
        )}
      </div>
    );
  } else if (fieldType === "freeFormText") {
    if (value) {
      return (
        <div className='w-full flex flex-row justify-between'>
          <p style={style}>{title}</p>
          <p style={style}>{value}</p>
        </div>
      );
    } else {
      return (
        <div className={`w-full flex ${getAlign()}`}>
          <p style={style}>{title}</p>
        </div>
      );
    }
  } else if (fieldType === "multiText") {
    let texts = [];
    let hasImage = false;
    for (let key in subFields) {
      if (subFields[key].type === "image") hasImage = true;
      texts.push({
        text: subFields[key],
        order: subFields[key].order,
        type: subFields[key].type,
        desc: subFields[key].description,
        style: subFields[key].style,
      });
    }
    texts.sort((a, b) => a.order - b.order);
    let classNames = "lg:text-[28px]";
    console.log(classNames);
    console.log(texts);
    if (style?.isBullet) {
      return (
        <div className={`w-full flex ${getAlign()}`}>
          <ul
            style={{
              ...style,
              listStyleType: style.listStyleType ? style.listStyleType : "disc",
            }}
          >
            {texts.map((item, i) => {
              console.log(item);
              if (item.type === "image") {
                return (
                  <img key={i} src={item.text.desc} alt='img' style={style} />
                );
              } else {
                return (
                  <li key={i} style={item.text.style}>
                    {item.text.title}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    } else if (hasImage) {
      return (
        <div className={`w-full flex ${getAlign()}`}>
          <ul
            className='flex flex-col'
            style={{
              ...style,
              listStyleType: style.listStyleType ? style.listStyleType : "disc",
            }}
          >
            {texts.map((item, i) => {
              console.log(item);
              if (item.type === "image") {
                return (
                  <img
                    key={i}
                    src={item.desc}
                    alt='img'
                    className='flex'
                    style={item.style}
                  />
                );
              } else {
                return (
                  <span key={i} style={item.text.style}>
                    {item.text.title}
                  </span>
                );
              }
            })}
          </ul>
        </div>
      );
    } else {
      return (
        <div className={`w-full flex ${getAlign()}`}>
          <p style={style}>
            {texts.map((item, i) => (
              <span key={i} style={item.style} className={`${classNames}`}>
                {item.text.title}
              </span>
            ))}
          </p>
        </div>
      );
    }
  } else if (fieldType === "image") {
    return (
      <div className={`w-full flex ${getAlign()}`}>
        <img src={description} alt='img' style={style} />
      </div>
    );
  } else if (fieldType === "sliderButton") {
    return (
      <SliderButton
        min={fieldValidator && (fieldValidator.threshold || 0)}
        max={fieldValidator && (fieldValidator.threshold2 || 0)}
        step={fieldValidator && (fieldValidator.step || 100)}
        style={style}
        darkMode={darkMode}
        disabled={disabled}
        align={align}
        currentValue={value}
        onChange={(eValue) => {
          onChange(eValue);
        }}
      />
    );
  } else if (fieldType === "sliderButtonCurrencyCO") {
    return (
      <SliderButton
        min={fieldValidator && (fieldValidator.threshold || 0)}
        max={fieldValidator && (fieldValidator.threshold2 || 0)}
        step={fieldValidator && (fieldValidator.step || 100)}
        style={style}
        darkMode={darkMode}
        disabled={disabled}
        align={align}
        onChange={(eValue) => {
          onChange(eValue);
        }}
        currentValue={value}
        country='es-CO'
        currency='COP'
      />
    );
  } else if (fieldType === "sliderButtonCurrencyCR") {
    return (
      <SliderButton
        min={fieldValidator && (fieldValidator.threshold || 0)}
        max={fieldValidator && (fieldValidator.threshold2 || 0)}
        step={fieldValidator && (fieldValidator.step || 100)}
        style={style}
        darkMode={darkMode}
        disabled={disabled}
        align={align}
        onChange={(eValue) => {
          onChange(eValue);
        }}
        currentValue={value}
        country='es-CO'
        currency='CRC'
      />
    );
  } else if (fieldType === "sliderButtonCurrencyNI") {
    return (
      <SliderButton
        min={fieldValidator && (fieldValidator.threshold || 0)}
        max={fieldValidator && (fieldValidator.threshold2 || 0)}
        step={fieldValidator && (fieldValidator.step || 100)}
        style={style}
        darkMode={darkMode}
        disabled={disabled}
        align={align}
        onChange={(eValue) => {
          onChange(eValue);
        }}
        currentValue={value}
        country='es-CO'
        currency='NIO'
      />
    );
  } else if (fieldType === "daneAddress") {
    return (
      <DaneInput
        onChange={(eVal) => {
          onChange(eVal);
        }}
        value={value}
        darkMode={darkMode}
        disabled={disabled}
        style={style}
        title={title}
      />
    );
  }
  // Default field
  else {
    const getColor = () => {
      if (style && style.color) {
        return style.color;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getBorderColor = () => {
      if (style && style.borderColor) {
        return style.borderColor;
      } else {
        return darkMode ? "white" : "black";
      }
    };

    const getDefaultMargin = () => {
      if (style && (style.margin || style.marginTop || style.marginBottom)) {
        return {
          margin: style.margin,
          marginTop: style.marginTop,
          marginBottom: style.marginBottom,
        };
      } else {
        return { margin: "15px 0" };
      }
    };
    return (
      <div className={`w-full flex ${getAlign()}`} style={getDefaultMargin()}>
        <input
          placeholder={title}
          style={{ ...style, color: getColor(), borderColor: getBorderColor() }}
          className='bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]'
          type='text'
          value={value}
          disabled={disabled}
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
        />
      </div>
    );
  }
};
