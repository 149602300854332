import { useEffect, useState } from "react";
import useQuery from "../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import { ButtonStyle } from "../utils/Buttons/Buttons";
import {
  getColorsByExecFlow,
  multiSigPagareV2CallbackAPI,
} from "./hyperFlowApi";
import { useHistory } from "react-router-dom";

const HyperFlowMultiSigPagareV2Callback = () => {
  let history = useHistory();
  //query params
  const query = useQuery();
  const execFlowId = query.get("execFlowId") ?? "error";
  const processId = query.get("signProcessId") ?? "error";
  const stepId = query.get("stepId") ?? "error";
  const code = query.get("code") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [darkMode, setDarkMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [msgEspera, setMsgEspera] = useState(
    "Espere un momento, estamos terminando su firma"
  );
  //get colors
  async function getColors() {
    let resp = await getColorsByExecFlow(execFlowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
  }

  async function elecSigCallbackF() {
    setTimeout(() => {
      setMsgEspera(
        "Estamos esperando las demás firmas, puede cerrar esta ventana"
      );
    }, 60000);
    setLoading(true);
    let keepAsking = true;
    while (keepAsking) {
      try {
        let resp = await multiSigPagareV2CallbackAPI(
          processId,
          execFlowId,
          stepId
        );
        if (resp.confirmed === true) {
          keepAsking = false;
        }
      } catch (e: any) {
        console.log(e);
      }
      await delay(2000);
    }

    await delay(2000);
    history.push("/hyperFlow/continueFlow?code=" + code);
  }

  useEffect(() => {
    getColors();
    elecSigCallbackF();
  }, []);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const customStyle = {
    "--fontColorPrimary": darkMode ? "#ffffff" : "#000000",
  } as React.CSSProperties;
  //Render
  return (
    <div className="hfMaster" style={customStyle}>
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          {loading && (
            <div>
              <ClipLoader loading={loading} size={150} color={"#FFFFFF"} />
              <h1
                className="txt-label-title-hf"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                {msgEspera}
              </h1>
            </div>
          )}

          {!loading && (
            <div>
              <h1
                className="txt-label2"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                Sus datos están completos
              </h1>
              <h1
                className="txt-label2"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                Continuemos con su proceso
              </h1>
              <div
                style={{
                  marginTop: "40px",
                }}
              ></div>
              <ButtonStyle
                onClick={() => {
                  history.push("/hyperFlow/continueFlow?code=" + code);
                }}
                text="Continuar"
                width="177px"
                height="5vh"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperFlowMultiSigPagareV2Callback;
