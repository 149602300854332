import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useQuery from "../utils/UseQuery";
import { getColorsByExecFlow, sendOtp, verifyOtp } from "./hyperFlowApi";
import OtpInput from "react-otp-input-rc-17";
import { ClipLoader } from "react-spinners";
import {
  hfErrorAlert,
  hfSuccessAlert,
  hfWarningAlert,
} from "./HyperFlowAlerts";
import { useTimer } from "react-timer-hook";
import addMinutes from "date-fns/addMinutes";

interface Props {
  // Define the props for your component here
}

const HyperFlowOtp: React.FC<Props> = (props) => {
  // Implement your component logic here
  //get query params
  let history = useHistory();
  //query params
  const query = useQuery();
  const execFlowId = query.get("execFlowId") ?? "error";
  const code = query.get("code") ?? "error";
  const [otpVal, setOtpVal] = useState("");
  const [loading, setLoading] = useState(false);

  const [bgColor, setBgColor] = useState(["#000000", "#000000"]);
  const [primaryButtonColor, setPrimaryButtonColor] = useState("#ffffff");
  const [secondaryButtonColor, setSecondaryButtonColor] = useState("#ffffff");
  const [primaryTextColor, setPrimaryTextColor] = useState("#ffffff");
  const [secondaryTextColor, setSecondaryTextColor] = useState("#ffffff");
  const [terciaryTextColor, setTerciaryTextColor] = useState("#ffffff");
  const [logo, setLogo] = useState("");
  const [font, setFont] = useState<string | null>(null);
  const [darkMode, setDarkMode] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [phone, setPhone] = useState("");

  const { minutes, seconds, restart } = useTimer({
    expiryTimestamp: addMinutes(new Date(), 5),
    autoStart: true,
    onExpire: () => setInputDisabled(true),
  });
  //get colors
  async function getColors() {
    let resp = await getColorsByExecFlow(execFlowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
    setPrimaryButtonColor(
      resp.primaryButtonColor ??
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)"
    );
    setSecondaryButtonColor(
      resp.secondaryButtonColor ??
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)"
    );
    if (resp.primaryTextColor) {
      setPrimaryTextColor(resp.primaryTextColor);
    } else {
      setPrimaryTextColor(darkMode ? "white" : "black");
    }
    if (resp.secondaryTextColor) {
      setSecondaryTextColor(resp.secondaryTextColor);
    } else {
      setSecondaryTextColor(darkMode ? "white" : "black");
    }
    setTerciaryTextColor(resp.tertiaryTextColor ?? "white");
    setLogo(resp.logoUrl ?? "");
    setFont(resp.font);
    setDarkMode(resp.darkMode);
  }

  async function sendOtpLocal() {
    setLoading(true);
    setShowResend(false);
    try {
      let resp = await sendOtp(execFlowId, code);
      if (!resp) {
        await hfErrorAlert("Error enviando OTP");
        setShowResend(true);
      } else if (resp.block === true) {
        await hfErrorAlert(
          "Has superado el límite de envíos de OTP, por favor intenta más tarde."
        );
        setShowResend(false);
      } else {
        restart(addMinutes(new Date(), resp.expirationMinutes));
        setPhone(resp.phone);
      }
    } catch (e: any) {
      console.error(e);
      // Fire swal
      await hfErrorAlert("Error enviando OTP");
      setShowResend(true);
    }
    setLoading(false);
    setTimeout(() => {
      setShowResend(true);
    }, 10000);
  }

  function next() {
    try {
      setLoading(true);
      history.push("/hyperFlow/continueFlow?code=" + code);
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

  async function verifyOtpLocal() {
    setLoading(true);
    if (otpVal.length < 6) {
      // Fire swal
      await hfWarningAlert("Por favor ingrese el código de 6 dígitos completo");
      setLoading(false);
      return;
    }
    let resp = await verifyOtp(execFlowId, code, otpVal);
    setLoading(false);
    switch (resp.status) {
      case "OK": {
        // await hfSuccessAlert("¡OTP verificado!");
        next();
        break;
      }
      case "INVALID": {
        await hfErrorAlert("Código inválido");
        setOtpVal("");
        break;
      }
      case "EXPIRED": {
        await hfErrorAlert("Código expirado");
        setOtpVal("");
        break;
      }
      case "MAX_TRIES": {
        await hfErrorAlert(
          "Has superado el límite de intentos, por favor intenta más tarde."
        );
        setOtpVal("");
        break;
      }
      default:
        hfErrorAlert("Error verificando OTP, intente de nuevo");
        break;
    }
  }

  const fontDictionary: { [key: string]: string } = {
    default: "'Open Sans', sans-serif",
    sourceSans: "'Source Sans 3', sans-serif",
  };

  const getFontFamily = () => {
    if (font) {
      return fontDictionary[font];
    } else {
      return fontDictionary.default;
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col justify-center items-center'>
          <h1 className='mb-4' style={{ color: primaryTextColor }}>
            Cargando...
          </h1>
          <ClipLoader loading={true} size={150} color={primaryTextColor} />
        </div>
      );
    } else {
      return (
        <div className='flex flex-col w-full h-full justify-center items-center'>
          <h3
            style={{ color: primaryTextColor }}
            className='text-center text-[24px] leading-[26.4px] font-bold mb-[10px]'
          >
            Confirmaremos tu <br /> No. de celular
          </h3>
          <p
            style={{ color: primaryTextColor }}
            className='w-[80%] text-start text-[18px] leading-[20px] font-semibold my-[50px]'
          >
            Ingresa el código de seguridad <br /> que te hemos enviado <br /> a:{" "}
            <span className='font-bold' style={{ color: secondaryTextColor }}>
              {" "}
              {phone}
            </span>
          </p>

          <OtpInput
            value={otpVal}
            onChange={setOtpVal}
            numInputs={6}
            isInputNum={true}
            isDisabled={loading || inputDisabled}
            inputStyle={{
              width: "38px",
              height: "39px",
              margin: "5px",
              border: "1px solid",
              borderColor: primaryTextColor,
              borderRadius: "8px",
              backgroundColor: "transparent",
              color: "#000",
            }}
          />
          <h3
            className='mt-8 mb-2 text-4xl text-center font-extrabold'
            style={{ color: primaryTextColor }}
          >
            {minutes}:
            {seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </h3>
          <p
            style={{ color: primaryTextColor }}
            className='text-[16px] text-center font-semibold'
          >
            Tiempo restante <br /> para ingresar el código
          </p>

          {showResend && (
            <button
              className='flex justify-center items-center text-[16px] font-semibold min-w-[160px] min-h-[56px] rounded-[30px] my-[40px]'
              style={{
                background: primaryButtonColor,
                color: terciaryTextColor,
              }}
              onClick={() => {
                sendOtpLocal();
                restart(addMinutes(new Date(), 5));
              }}
            >
              RE ENVIAR CÓDIGO
            </button>
          )}

          <div className='flex flex-row justify-evenly w-full items-center mt-[10px]'>
            <button
              className='flex justify-center items-center text-[16px] font-semibold min-w-[160px] min-h-[56px] rounded-[30px]'
              onClick={() => {
                history.goBack();
              }}
              style={{
                background: secondaryButtonColor,
                color: terciaryTextColor,
              }}
            >
              CANCELAR
            </button>
            <button
              className='flex justify-center items-center text-[16px] font-semibold min-w-[160px] min-h-[56px] rounded-[30px]'
              onClick={() => {
                verifyOtpLocal();
              }}
              style={{
                background: primaryButtonColor,
                color: terciaryTextColor,
              }}
            >
              CONFIRMAR
            </button>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    // call send otp
    getColors();
    sendOtpLocal();
  }, []);

  return (
    <div
      className='flex justify-center items-center'
      style={{
        background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
        minHeight: "100vh",
        width: "100%",
        overflow: "auto",
        fontFamily: getFontFamily(),
      }}
    >
      {renderContent()}
    </div>
  );
};

export default HyperFlowOtp;
