import React, { useEffect, useState } from "react";
import StyleProperties from "../../dtos/StyleProperties";

export interface DaneInputProps {
  title?: string;
  value?: string;
  onChange: (value: string) => void;
  style?: StyleProperties;
  darkMode?: boolean;
  disabled?: boolean;
}

export const DaneInput = ({
  title,
  value,
  onChange,
  style,
  darkMode,
  disabled,
}: DaneInputProps) => {
  const viaOptions = [
    { label: "Calle" },
    { label: "Carrera" },
    { label: "Diagonal" },
    { label: "Transversal" },
    { label: "Autopista" },
    { label: "Avenida" },
    { label: "Avenida Calle" },
    { label: "Avenida Carrera" },
    { label: "Circunvalar" },
    { label: "Peatonal" },
    { label: "Troncal" },
    { label: "Variante" },
    { label: "Vía" },
    { label: "Kilómetro" },
    { label: "Finca" },
    { label: "Hacienda" },
  ];

  const daneMapper: { [key: string]: string } = {
    Calle: "CL",
    Carrera: "CR",
    Diagonal: "DG",
    Transversal: "TV",
    Autopista: "AUT",
    Avenida: "AV",
    "Avenida Calle": "AC",
    "Avenida Carrera": "AK",
    Circunvalar: "CRV",
    Peatonal: "PTL",
    Troncal: "TR",
    Variante: "VR",
    Vía: "VIA",
    Kilómetro: "KM",
    Finca: "FCA",
    Hacienda: "HC",
  };
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
  } | null>(null);
  const [firstValue, setFirstValue] = useState<string>("");
  const [secondValue, setSecondValue] = useState<string>("");
  const [thirdValue, setThirdValue] = useState<string>("");
  const [showRural, setShowRural] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const getColor = () => {
    if (style && style.color) {
      return style.color;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getBorderColor = () => {
    if (style && style.borderColor) {
      return style.borderColor;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getDefaultMargin = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
      };
    } else {
      return { margin: "15px 0" };
    }
  };

  const getSelectedColor = (value: { label: string }) => {
    if (value.label === selectedOption?.label) {
      if (style && style.selectedColor) {
        return style.selectedColor;
      } else {
        return darkMode ? "white" : "black";
      }
    } else {
      if (style && style.unselectedColor) {
        return style.unselectedColor;
      } else {
        return darkMode ? "black" : "white";
      }
    }
  };

  const handleSelectOptionClick = (option: { label: string }) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (value) {
      const values = value.split(" ");
      const selected = viaOptions.find((option) => option.label === values[0]);
      if (selected) {
        setSelectedOption(selected);
      }
      setFirstValue(values[1]);
      setSecondValue(values[2]);
      setThirdValue(values[3]);
    }
  }, []);

  useEffect(() => {
    if (
      selectedOption?.label === "Finca" ||
      selectedOption?.label === "Hacienda"
    ) {
      setShowRural(true);
      let daneValue = daneMapper[selectedOption.label] ?? selectedOption.label;
      let sec = secondValue && secondValue !== "" ? ` VRD ${secondValue}` : "";
      console.log("DaneInput value", `${daneValue} ${firstValue} ${sec}`);
      onChange(`${daneValue} ${firstValue} ${sec}`.trim().toUpperCase());
    } else if (selectedOption) {
      setShowRural(false);
      let daneValue = daneMapper[selectedOption.label] ?? selectedOption.label;
      console.log(
        "DaneInput value",
        `${daneValue} ${firstValue} ${secondValue} ${thirdValue}`
      );
      onChange(
        `${daneValue} ${firstValue} ${secondValue} ${thirdValue}`
          .trim()
          .toUpperCase()
      );
    }
  }, [selectedOption, firstValue, secondValue, thirdValue, onChange]);

  // sleep functoin
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const close = async () => {
    console.log("sleep");
    await sleep(150);
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen) {
      document.getElementById("tipoVia")?.focus();
    }
  }, [isOpen]);

  return (
    <div className={`w-full flex flex-col`} style={getDefaultMargin()}>
      <div className="flex flex-row justify-between">
        <h6
          style={{ color: getColor() }}
          className="text-[15px] leading-[23px] font-bold self-start text-start"
        >
          {title || "Dirección"}
        </h6>
        <div className="flex flex-col min-w-[80px] max-w-[80px] items-center">
          <h6
            style={{ color: getColor() }}
            className="text-[15px] leading-[23px] font-bold self-center text-start "
          >
            {showRural ? "Nombre" : "# Via"}
          </h6>
        </div>
      </div>
      <div className="w-full flex flex-col space-y-3">
        <div className="w-full flex flex-row justify-between">
          <div className="relative min-w-[190px] flex flex-col">
            <input
              id="tipoVia"
              disabled={disabled}
              type="text"
              value={
                selectedOption && selectedOption.label
                  ? selectedOption.label
                  : ""
              }
              readOnly
              onClick={() => setIsOpen((prev) => !prev)}
              onBlur={() => close()}
              placeholder={"TIPO DE VIA                "}
              style={{
                ...style,
                padding: "0",
                color: getColor(),
                borderColor: getBorderColor(),
              }}
              className={`bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center  min-w-[190px] h-[38px] cursor-pointer`}
            />
            <div
              className="absolute right-3 top-2 cursor-pointer flex justify-center items-center"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div
                className="border-[1px] h-[20px] mr-3"
                style={{ borderColor: getColor() }}
              ></div>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.90735e-06 3.33882C0.00308228 2.80387 0.116661 2.27562 0.333303 1.78865C0.560415 1.26198 0.931032 0.813264 1.4009 0.496092C1.87076 0.17892 2.42001 0.00669988 2.98305 0L17.015 0C17.578 0.00669988 18.1273 0.17892 18.5972 0.496092C19.067 0.813264 19.4376 1.26198 19.6648 1.78865C19.9448 2.39465 20.0526 3.06888 19.976 3.73449C19.8994 4.4001 19.6414 5.03032 19.2315 5.55335L12.2155 14.2411C11.9501 14.5818 11.6131 14.8569 11.2293 15.046C10.8456 15.2351 10.425 15.3333 9.99903 15.3333C9.57302 15.3333 9.15249 15.2351 8.76874 15.046C8.385 14.8569 8.04791 14.5818 7.78258 14.2411L0.766592 5.55335C0.269764 4.92711 -0.000940323 4.1451 1.90735e-06 3.33882Z"
                  fill={getColor()}
                />
              </svg>
            </div>
            {isOpen && (
              <ul
                className="absolute mt-10  min-w-[190px] border-[1px] rounded-xl shadow-lg text-start max-h-[200px] overflow-auto"
                style={{
                  ...style,
                  borderColor: getBorderColor(),
                  backgroundColor: getColor(),
                  zIndex: 1000,
                }}
              >
                {viaOptions.map((option, index) => (
                  <li
                    key={index + option.label}
                    style={{ color: getSelectedColor(option) }}
                    onClick={() => handleSelectOptionClick(option)}
                    className="px-4 py-2 cursor-pointer"
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <input
              placeholder={showRural ? "" : "ej. 127b"}
              style={{
                ...style,
                color: getColor(),
                borderColor: getBorderColor(),
              }}
              className={
                showRural
                  ? "bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center min-w-[100px] max-w-[100px] h-[38px]"
                  : "bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center min-w-[70px] max-w-[80px] h-[38px]"
              }
              type="text"
              value={firstValue}
              disabled={disabled}
              onChange={(ev) => {
                setFirstValue(ev.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row lg:space-x-5 items-center justify-between lg:justify-start">
          <div className="flex flex-col">
            <h6
              style={{ color: getColor() }}
              className="text-[15px] leading-[23px] font-bold self-start text-start "
            >
              {showRural ? "Vereda" : "Primer número"}
            </h6>
            <input
              placeholder={showRural ? "" : "ej. 18a"}
              style={{
                ...style,
                color: getColor(),
                borderColor: getBorderColor(),
              }}
              className="bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-[120px] max-w-[140px] h-[38px]"
              type="text"
              value={secondValue}
              disabled={disabled}
              onChange={(ev) => {
                setSecondValue(ev.target.value);
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="h-[22px]" />
            <p className="font-bold text-[16px]" style={{ color: getColor() }}>
              -
            </p>
          </div>
          {!showRural && (
            <div className="flex flex-col">
              <h6
                style={{ color: getColor() }}
                className="text-[15px] leading-[23px] font-bold self-start text-start "
              >
                {"Segundo número"}
              </h6>
              <input
                placeholder={"ej. 35"}
                style={{
                  ...style,
                  color: getColor(),
                  borderColor: getBorderColor(),
                }}
                className="bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-[120px] max-w-[140px] h-[38px]"
                type="text"
                value={thirdValue}
                disabled={disabled}
                onChange={(ev) => {
                  setThirdValue(ev.target.value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
